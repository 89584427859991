import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 16,
      width: '100%',
      maxWidth: 600,
    },
  },
  subheader: {
    borderBottom: '1px solid #0002',
  },
  container: {
    maxWidth: '100%',
    padding: '.75em 2.5em',
    '@media (max-width:600px)': {
      padding: '.75em 1.5em',
    },
    '@media (max-width:450px)': {
      padding: '.75em',
    },
  },
  wrapper: {
    width: 'calc(100% - 40px)',
    maxWidth: 600,
    margin: '1em auto 1em',
    padding: '.5em 1.5em',
    '@media (max-width:600px)': {
      width: 'calc(100% - 10px)',
      margin: '.5em 0',
      padding: '.5em .25em',
    },
  },
  form: {
    margin: '12px 0 8px',
    '& .MuiGrid-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTextField-root': {
      width: 'calc(100% - 16px)',
      minHeight: 67,
    },
  },
  label: {
    color: fade(theme.palette.text.primary, 0.54),
  },
  dropzone: {
    margin: '12px 0 20px',
    '& label': {
      display: 'block',
      marginBottom: 12,
    },
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  button: {
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export default useStyles;
