import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetValueToPath } from '../utils/functions';
import { objectPathValue } from '../utils/ObjectUtils';

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  root: {
    '& .MuiInputBase-input': {
      fontFamily: 'Muli',
      color: '#606060',
    },
  },
}));

export const TextFieldUI = ({
  type,
  name,
  label,
  props,
  disabled = false,
  readonly = false,
  classes = useStyles(),
}) => {
  const { values, handleChange, errors } = props;
  const value = GetValueToPath(values, name) || '';

  return (
    <TextField
      type={type}
      className={classes.root}
      label={label}
      name={name}
      id={name}
      value={value}
      onChange={handleChange}
      helperText={objectPathValue(errors, name)}
      disabled={disabled}
      InputProps={{
        readOnly: readonly,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes && classes.helperTextRoot,
        },
      }}
    />
  );
};
