import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ContainerUIBox = withStyles({
  root: {
    position: 'relative',
    '& > div[class^="makeStyles-navbar"]': {
      position: 'absolute',
      top: 0,
    },
  },
})(Box);

const useStyles = makeStyles({
  container: {
    '& > div.MuiContainer-root.article': {
      position: 'absolute',
      top: 0,
      paddingTop: 65,
      paddingBottom: 45,
      '@media (max-width:600px)': {
        paddingTop: 75,
      },
    },
  },
});

export const ContainerUI = ({ children }) => {
  const styles = useStyles();
  return (
    <ContainerUIBox className={styles.container}>
      {children}
    </ContainerUIBox>
  );
};

export default ContainerUI;
